import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import header from '../../assets/IMG_1137.JPG'
import data from './data'
import './header.css'

const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])
  return (
    <header id="header"><div className="container header__container">
      <div className="header__profile" data-AOS="fade-in">
        <img src={header} alt="Header Portait" />
        </div>
        <h3 data-AOS="fade-up">Marquice Bowman</h3>
        <p data-AOS="fade-up">
         Lets make some cool internet stuff!
        </p>
        <div className="header__cta" data-AOS="fade-up">
          <a href="#contact" className='btn primary'>Let's Talk</a>
          <a href="#portfolio" className='btn light'>My Work</a>
        </div>
        <div className="header__socials">
          {
            data.map(item => <a key={item.id} href={item.link} target="blank" rel='noopener noreferrer'>{item.icon}</a>)
          }
        </div>
        </div>
        </header>
  )
}

export default Header