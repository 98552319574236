import { HiDownload } from 'react-icons/hi'
import CV from '../../assets/1.4.24.pdf'
import AboutImage from '../../assets/IMG_1137.JPG'
import Card from '../../components/Card'
import './about.css'
import data from './data'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About Image" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Me</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
            <p>
              Hi My name is Marquice Bowman I am a Developer with a Finance background, focused on delivering the best experience for you and your clients.
              Im into sports (Lakers and Commanders), Martial Artist, Fitness, Food (Ice Cream and Steak), Gaming. 
            </p>
            <p>
              This is what I do, I make websites and applications for customers and myself. 
              I enjoy figuring out the solution to make things run easy. 
              I work on databases from MySQL to sqlite. My languages are python, javascript, wordpress, php, sql, and english. 
              I also use react, django, php, and wordpress. 
            </p>
                <a href={CV} download className='btn primary'>Download CV <HiDownload/></a>
            </div>
        </div>
    </section>
    )
  }
  
  export default About;