import Project from "./Project.jsx"

const Projects = ({projects}) => {
  return (
    <div className="portfolio__projects" data-AOS="fade-up">
        {
            projects.map(project => (
                <Project key= {project.id} project={project}/>
            ))
        }
        </div>
  )
}

export default Projects