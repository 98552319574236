
import { useEffect, useRef, useState } from 'react';
import Modal from './components/Modal.jsx';
import { useThemeContext } from './context/theme-context';
import About from './sections/about/About.jsx';
import Contact from './sections/contact/Contact.jsx';
import FAQs from './sections/faqs/FAQs';
import FloatingNav from './sections/floating-nav/FloatingNav';
import Footer from './sections/footer/Footer';
import Header from './sections/header/Header';
import Navbar from './sections/navbar/Navbar';
import Portfolio from './sections/portfolio/Portfolio.jsx';
import Services from './sections/services/Services';
import Testimonials from './sections/testimonials/Testimonials.jsx';
import Theme from './theme/Theme';

const App = () => {
  const {themeState} = useThemeContext();

  const mainRef = useRef();
  const [showFloatingNav, setShowFloatingNav] = useState(true);
  const [siteYPostion, setSiteYPosition] = useState(0)

  const showFloatingNavHandler = () => {
    setShowFloatingNav(true);
  }

  const hideFloatingNavHandler = () => {
    setShowFloatingNav(false);
  }

  // check if floating nav should be shown or hidden
  const floatingNavToggleHandler = () => {
    // check if we scrolled up or down at least 20px
    if(siteYPostion < (mainRef?.current?.getBoundingClientRect().y - 20) || siteYPostion > (mainRef?.current?.getBoundingClientRect().y + 20)) {
      showFloatingNavHandler();
    } else {
      hideFloatingNavHandler();
    }

    setSiteYPosition(mainRef?.current?.getBoundingClientRect().y);
  }

  useEffect(() => {
    const checkYPosition = setInterval(floatingNavToggleHandler, 2000);

    // cleanup function
    return () => clearInterval(checkYPosition);
  }, [siteYPostion])
  
  return (
    <main className={`${themeState.primary} ${themeState.background}`} ref={mainRef}>
      <Navbar/>
      <Header/>
      <About/>
      <Services/>
      <Portfolio/>
      <Testimonials/>
      <FAQs/>
      <Contact/>
      <Footer/>
      <FloatingNav/>
      <Theme/>
      <Modal/>
        {showFloatingNav && <FloatingNav/>}
    </main>
  )
}

export default App;