import { BiHappyHeartEyes } from 'react-icons/bi';
import { FaAward } from 'react-icons/fa';
import { TbBooks } from 'react-icons/tb';


const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: '3+ Years Working'},
    {id: 2, icon: <TbBooks/>, title: 'Projects', desc: '20+ Completed'},
    {id: 3, icon: <BiHappyHeartEyes/>, title: 'Clients', desc: '7+ happy clients'}
    ]



    export default data;