import Image2 from '../../assets/GoodEats.png'
import Image4 from '../../assets/MusicPlayer.png'
import Image5 from '../../assets/Pong.png'
import Image1 from '../../assets/Quickbets.png'
import Image3 from '../../assets/StreetfighterblogScreenShot.png'

const data = [
    {
        id: 1,
        category: 'frontend',
        image: Image1,
        title: "Quick Bets",
        desc: "QuickBets is a web application that allows users to quickly place bets on various events. The application is built using React for the front end and Python Django for the back end.",
        demo: 'http://na.com',
        github: 'https://github.com/GoldenMarquice/Betting-App'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "Good Eats (Frontend and Backend)",
        desc: "This a project made from react and python, frontend and backend respectively. This is to demonstrate reacts ability to simulate a ordering system, adding products, calculating products, and the various states of react.",
        demo: 'http://na.com',
        github: 'https://github.com/GoldenMarquice/ResturantGoodEats'
    },
    {
        id: 3,
        category: 'backend',
        image: Image3,
        title: "StreetFighter Blog (Backend)",
        desc: "This is a StreetFighter blog made from python to manage a posting system to create and make post.",
        demo: 'https://youtu.be/Qhpw9A_vCT4',
        github: 'https://github.com/GoldenMarquice/StreetFighter-Blog'
    },
    {
        id: 4,
        category: 'backend',
        image: Image4,
        title: "Music Player (Frontend)",
        desc: "This is a Music Player made with react that plays music through an animated player using local storage to house the music ",
        demo: 'https://youtu.be/G9HYfa2rYyk',
        github: 'https://github.com/GoldenMarquice/musicplayer'
    },
    {
        id: 5,
        category: 'frontend',
        image: Image5,
        title: "Pong! (Frontend)",
        desc: "This is a Game = Pong from Atari made with JS, HTML, CSS to show animation and 2D effects ",
        demo: '/Users/main/Desktop/portfolio/portfolio/public/pong.html',
        github: 'https://github.com/GoldenMarquice/Pong-'
    },
]


export default data